import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth';
import quiz from './modules/quiz';
import layout from './modules/layout';
import firebase from './modules/firebase';

import { getContactsInfo } from "@/API/contact";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMainHeader: false,
    isMainFooter: false,
    termsTitle: null,
    termsContent: null,
    contacts: null,
    restrictedModule: null
  },
  getters: {
    isTermsModal(state) {
      return state.isTermsModal;
    },
    restrictedModule(state) {
      return state.restrictedModule;
    }
  },
  mutations: {
    TOGGLEMAINHEADER(state, payload) {
      state.isMainHeader = payload;
    },
    TOGGLEMAINFOOTER(state, payload) {
      state.isMainFooter = payload;
    },
    UPDATE_TERMS_MODAL(state, payload) {
      state.termsTitle = payload.title ? payload.title : null;
      state.termsContent = payload.content ? payload.content : null;
    },
    UPDATE_CONTATCS_INFO(state, contacts) {
      state.contacts = contacts
    },
    UPDATE_RESTRICTED_MODULE(state, payload) {
      state.restrictedModule = payload;
    }
  },
  actions: {
    getContacts({commit}) {
      getContactsInfo().then(res => {
        if (res.status == 200) {
          // console.log(res.data);
          commit('UPDATE_CONTATCS_INFO', res.data?.data)
        }
      })
    }
  },
  modules: {
    auth,
    quiz,
    layout,
    firebase
  },
});
