<template>
    <notifications :duration="5000" position="bottom right">
        <template slot="body" slot-scope="{ item, close }">
            <div
                :class="item.type"
                class="notification bg-white d-flex align-items-center"
            >
                <div>
                    <img src="@/assets/imgs/notify-logo.png" alt="logo" />
                </div>
                <div class="content">
                    <p v-if="item.title" class="title mb-0">
                        {{ item.title }}
                    </p>
                    <button class="close mx-1 btn" @click="close">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.952"
                            height="13.952"
                            viewBox="0 0 13.952 13.952"
                        >
                            <path
                                d="M21.452,8.905,20.047,7.5l-5.571,5.571L8.905,7.5,7.5,8.905l5.571,5.571L7.5,20.047l1.405,1.405,5.571-5.571,5.571,5.571,1.405-1.405-5.571-5.571Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#959595"
                            />
                        </svg>
                    </button>
                    <p class="mb-0">{{ item.text }}</p>
                </div>
            </div>
        </template>
    </notifications>
</template>

<script>
export default {
    name: "Notify",
};
</script>

<style lang="scss" scoped>
.vue-notification {
    font-size: 1rem;
    padding: 1rem;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.12);
    &-group {
        top: auto !important;
        bottom: 5rem !important;
        padding: 0 0.5rem 0 0 !important;
        @media screen and (max-width: 580px) {
            bottom: 7rem !important;
        }
    }
    &.success {
    }
}
.notification {
    width: calc(300px - 1rem) !important;
    font-size: 0.9rem;
    padding: 0.5rem;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.12);
    border-radius: 20px 0 0 20px;
    margin: 0.5rem 0 0.5rem 0.5rem;

    &.danger {
        p {
            color: rgb(218, 45, 45);
        }
    }

    .content {
        width: calc(100% - 60px);
    }
}
</style>