import { API, logError } from "./index";

const sendFCMToken = async (data) => {
    try {
        return await API().post('/updateFcm_token', data);
    } catch (error) {
        return logError(error);
    }
};

export {
    sendFCMToken
};