<template>
    <b-modal id="confirmation-modal" no-close-on-backdrop static centered hide-footer>
        <template #modal-header="{ close }">
            <div></div>
            <b-button size="sm" @click="close()"> X </b-button>
        </template>
        <div class="py-3">
            <div class="text-center">
                <h5 class="mb-4">{{ title }}</h5>
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <button
                    @click="confirmAction(false)"
                    type="button"
                    :disabled="confirming"
                    class="btn btn-brand btn-danger mx-1"
                >
                    {{ cancel || $t('cancel') }}
                </button>
                <button
                    @click="confirmAction(true)"
                    type="button"
                    :disabled="confirming"
                    class="btn btn-brand mx-1 btn-mx-height"
                >
                    <div v-if="confirming" class="px-2">
                        <b-spinner  variant="light"></b-spinner>
                    </div>
                    <span v-else class="text-white">{{ confirm || $t('confirm') }}</span>
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "ConfirmAction",
    props: {
        title: {
            type: String
        },
        confirm: {
            type: String,
        },
        cancel: {
            type: String,
        },
    },
    data() {
        return {
            confirming: false,

        };
    },
    methods: {
        confirmAction(action) {
            if (!action) {
                this.$bvModal.hide('confirmation-modal');
                return;
            }
            this.confirming = true;
            this.$emit('confirmAction', action);
        }
    },
    mounted() {
        this.$root.$on("bv::modal::hide", () => {
            this.confirming = false;
        });
    },
};
</script>

<style lang="scss">
    #confirmation-modal {
        &.modal .modal-dialog {
            max-width: 500px !important;
        }
    }
</style>
