import { API, logError } from "./index";

const getFAQ = async (type) => {
    try {
        return await API().get(`/faq?type=${ type }`);
    } catch (error) {
        return logError(error);
    }
}

export {
    getFAQ
}