import axios from "axios";
import store from "@/store";
import router from "@/router";
import config from "@/config";


function API() {
    return axios.create({
        baseURL: config.baseURL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${store.getters['auth/token']}`,
            'lang': 'en'
        }
    });
}

const logError = (error) => {
    // console.log(error.response);
    if (error.response?.data?.message) {
        // console.log(`%cError: \n API => {baseUrl}${error.response?.config?.url}\n method => ${error.response?.config?.method} \n code => ${error.response?.status} \n msg => ${error.response.data.message}`, "background: #007EC6; color: white; padding: 5px 2px; border-radius: 3px; margin-bottom: 1px;");

        isAuth(error.response.data.message)
        return error.response;
    }

    // console.log(`%cError: \n API => {baseUrl}${error.response?.config?.url} \n method => ${error.response?.config?.method} \n code => ${error.response?.status} \n msg => ${error.message}`, "background: #007EC6; color: white; padding: 5px 2px; border-radius: 3px; margin-bottom: 1px;");

    isAuth(error.message)
    return error.response;
};

const isAuth = (errorMessage) => {
    if (errorMessage == 'Unauthenticated.') {
        localStorage.removeItem('DLPToken')
        localStorage.removeItem('DLPUser')
        store.dispatch("auth/logoutUser");

        router.push('/')
    }
}

export {
    API,
    logError
};