export default {
    namespaced: true,
    state: () => ({
        isFooterPartner: false,
        isSearch: false
    }),
    getters: {
        direction() {
            return document.querySelector('body').getAttribute('dir');
        }
    },
    mutations: {
        UPDATEFOOTERSTATUS(state, payload) {
            state.isFooterPartner = payload;
        },
        UPDATE_SEARCH(state) {
            state.isSearch = !state.isSearch;
        }
    },
    actions: {},
}