import { API, logError } from "./index";

const getContactsInfo = async () => {
    try {
        return await API().get('/contact-info');
    } catch (error) {
        return logError(error);
    }
}

const sendContactForm = async (form) => {
    try {
        return await API().post('/contact-us', form);
    } catch (error) {
        return logError(error);
    }
}

export {
    getContactsInfo,
    sendContactForm
}