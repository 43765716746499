export default {
    home: "Home",
    firstname: "First name",
    lastname: "Last name",
    email: "E-mail Address",
    country: "Country",
    specialty: "Specialty",
    subSpecialty: "Sub specialty",
    licenceNO: "License",
    hospital: "Hospital",
    fNameError: "Enter first name (at least 3 charsets)",
    lNameError: "Enter last name (at least 3 charsets)",
    emailError: "Enter a valid Email!",
    countryError: "Select your country!",
    specialtyError: "Select specialty!",
    subSpecialtyError: "Enter your subspecialty (at least 3 charsets)",
    licenceError: "Enter your licence NO.",
    hospitalError: "Enter hospital name (at least 3 charsets)",
    password: "Password",
    requiredErorr: "Answer all required questions!",
    validEmailError: "Enter your valid E-mail!",
    passwordError: "Enter the password!",
    acceptTermsError: "Make sure you read the terms && conditions!",
    disconnected: "No internet connection",
    connected: "Internet is connected",
    Male: "Male",
    Female: "Female",
    noMobileRegistered: "No mobile registered yet.",
    verificationCode: "Verification Code",
    nameRequired: "The name is required!",
    titleRequired: "The message title is required!",
    mobileRequired: "The mobile is required!",
    bodyRequired: "The message body is required!",
    fnameRequired: "First name is required",
    lnameRequired: "Last name is required",
    confirm: "Confirm",
    cancel: "Cancel",
    logoutConfirm: "Do you really want to logout?",
    contact: "Contact",
    contactUs: "Contact us",
    contactInfo: "Contact info",
    name: "Name",
    yourname: "Your name",
    msgTitle: "Message title",
    yourEmail: "Your E-mail",
    yourMobile: "Your mobile NO.",
    mobile: "Mobile",
    yourMsg: "Your message",
    msg: "Message",
    sendMsg: "Send message",
    about: "About",
    aboutUs: "About us",
    aboutDLP: "About DLP",
    partners: "Partners",
    events: "Events",
    joinUs: "Join us",
    signIn: "Sign in",
    logout: "Logout",
    myAccount: "My account",
    completeProfile: "Complete Profile",
    search: "Search",
    date: "Date",
    noResults: "No results found",
    next: "Next",
    prev: "Prev",
    termsAndConditions: "Terms and Conditions",
    noModulesFound: "No modules found for this partner",
    enterCorrectCode: "Enter the correct code!",
    emailResetPassword: "We've sent a temp. password to your email, after login you can update your new password.",
    noNotifications: "No Notifications Yet",
    restrictedModule: "You are not authorized to see this module",
    fullName: "Fullname",
    fullNameError: "Enter your Fullname",
    addedToFavorite: 'Added to your favorite list.',
    removedToFavorite: 'Removed to your favorite list.',


}