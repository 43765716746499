<template>
    <div 
    :class="{ show : isSearch }"
    class="search-top">
        <div class="container search-box">
            <div class="row">
                <div class="col-lg-10 col-xl-8 mx-auto">
                    <form
                        @submit.prevent="searchSomething()"
                        class="d-flex align-items-center w-100"
                    >
                        <input
                            v-model="keyword"
                            type="search"
                            class="form-control"
                            :placeholder="`${$t('search')}...`"
                        />
                        <button type="submit" class="btn">{{ $t('search') }}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Search",
        data() {
            return {
                keyword: null
            }
        },
        computed: {
            isSearch() {
                return this.$store.state?.layout?.isSearch;
            }
        },
        methods: {
            searchSomething() {}
        }
    }
</script>

<style lang="scss" scoped>

</style>