import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const noHeaderRoutes = ['/quiz'];
const noFooterRoutes = ['/quiz'];
const checkLayout = (to) => {
  noHeaderRoutes.forEach(page => {
    if (to.path.includes(page)) {
      store.commit(`TOGGLEMAINHEADER`, false);
    } else {
      store.commit(`TOGGLEMAINHEADER`, true);
    }
    
  });

  noFooterRoutes.forEach(page => {
    if (to.path.includes(page)) {
      store.commit(`TOGGLEMAINFOOTER`, false);
    } else {
      store.commit(`TOGGLEMAINFOOTER`, true);
    }
  });

  if (to.path == '/partners') {
    store.commit(`layout/UPDATEFOOTERSTATUS`, true);
  } else {
    store.commit(`layout/UPDATEFOOTERSTATUS`, false);
  }
}

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // not logged in
    if (!localStorage.getItem('DLPToken')) next('/');
  }

  if (localStorage.getItem('DLPToken')) {

    if (!store.state?.auth?.isLogin) {
      store.commit(`auth/UPDATEAUTHINFO`, 'update');
    }
  
    to.path == '/' ? next('/dashboard') : next();
  }
  
  checkLayout(to, from);
  next();
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
