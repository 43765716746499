<template>
  <div id="app" class="main-container">
    <Search />
    <Notify />
    <!-- classes="notifications" -->

    <MainHeader v-if="$store.state.isMainHeader" />
    <MobileMenu />
    <main
      :class="[$store.state.isMainHeader ? '' : 'border-top']"
      class="content-wrapper bg-white overflow-hidden"
    >
      <router-view />
    </main>
    <MainFooter v-if="$store.state.isMainFooter" />

    <RestrictedModuleModal />

    <!-- Terms modal -->
    <TermsModal />

    <!-- ForgetPassModal -->
    <ForgetPassModal />

    <!-- MustLoginModal -->
    <MustLoginModal />

    <MustUpdateDataModal />

    <IsExpiredQuizModal />

    <FeedbackModal />

    <VideoViewerModal />
  </div>
</template>

<script>
import Search from "@/components/Search.vue";
import Notify from "@/components/Notify";
import MainHeader from "@/components/MainHeader";
import MobileMenu from "@/components/MobileMenu";
import MainFooter from "@/components/MainFooter";
import TermsModal from "@/components/TermsModal.vue";
import ForgetPassModal from "@/components/ForgetPassModal";
import MustLoginModal from "@/components/MustLoginModal";
import MustUpdateDataModal from "@/components/MustUpdateDataModal";
import RestrictedModuleModal from "@/components/RestrictedModuleModal";
import FeedbackModal from "@/components/FeedbackModal";
import IsExpiredQuizModal from "@/components/IsExpiredQuizModal";

// import { sendFCMToken } from "@/API/firebase";

export default {
  name: "App",
  components: {
    Search,
    Notify,
    MainHeader,
    MobileMenu,
    MainFooter,
    TermsModal,
    ForgetPassModal,
    MustLoginModal,
    MustUpdateDataModal,
    RestrictedModuleModal,
    FeedbackModal,
    IsExpiredQuizModal,
    VideoViewerModal: () => import('@/components/VideoViewerModal')
  },
  data() {
    return {
      internetNotified: false,
    };
  },
  methods: {
    checkInternetConnection() {
      // notify if no internet
      window.addEventListener("offline", () => {
        this.$notify({
          type: "danger",
          fixed: true,
          text: this.$t("disconnected"),
        });
        // this.$router.push('/network-error');
      });

      // notify if no internet
      window.addEventListener("online", () => {
        this.$notify({
          fixed: true,
          text: this.$t("connected"),
        });
        // this.$router.push('/');
      });
    },
  },
  mounted() {
    this.checkInternetConnection();

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === 'restricted-modal' && this.$route.path?.includes('/dashboard?is_restricted')) {
        this.$router.push(`/dashboard`);
      }

      if(modalId === 'video-viewer-modal') {
        this.$store.commit('quiz/UPDATE_CURRENT_VIDEO_ID', null);
      }
    })

    // this.$bvModal.show('feedback-modal')

    this.$store.dispatch("getContacts");

    // if (localStorage.getItem('DLPToken') && location.protocol == 'https:') {
    //     this.$store.dispatch('firebase/getUserNotifications');
    //     this.$store.dispatch('firebase/updateMessagingFCMToken', this);
    // }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/base";
</style>
