<template>
    <b-modal id="feedback-modal" no-close-on-backdrop static centered hide-footer>
        <template #modal-header="{ close }">
            <div></div>
            <b-button size="sm" @click="close()"> X </b-button>
        </template>
        <div>
          <div class="d-flex align-items-center mb-4">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="29.25" viewBox="0 0 31.5 29.25">
                <path d="M2.25,32.625h31.5L18,3.375Zm17.438-4.5H16.313V24.75h3.375Zm0-5.625H16.313V15.75h3.375Z" transform="translate(-2.25 -3.375)" fill="#ffd064"/>
              </svg>
            </span>
            <h5 class="mx-2 mb-0">Feedback</h5>
          </div>
          <template v-if="loading">
             <div class="mb-4">
              <div v-for="(feedback, index) in 3" :key="index" class="d-flex align-items-center justify-content-between mb-1">
                <p class="mb-0">
                  <b-skeleton width="12rem" height="1.5rem"></b-skeleton>
                </p>
                <div class="d-flex align-items-center">
                  <b-skeleton class="mx-1" width="1.5rem" height="1.5rem"></b-skeleton>
                  <b-skeleton class="mx-1" width="1.5rem" height="1.5rem"></b-skeleton>
                  <b-skeleton class="mx-1" width="1.5rem" height="1.5rem"></b-skeleton>
                  <b-skeleton class="mx-1" width="1.5rem" height="1.5rem"></b-skeleton>
                  <b-skeleton class="mx-1" width="1.5rem" height="1.5rem"></b-skeleton>
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <b-skeleton width="100%" height="6rem"></b-skeleton>
            </div>
            <div class="d-flex justify-content-center">
              <b-skeleton width="8rem" height="2.3rem"></b-skeleton>
            </div>
          </template>
          <template v-else>
            <div class="mb-4">
              <div v-for="(feedback, index) in feedbacks" :key="index" class="d-flex align-items-center justify-content-between mb-1">
                <p class="mb-0">
                  {{ feedback.question }}
                </p>
                <div class="feedback">
                  <div class="rating">
                    <input type="radio" :checked="feedback.rate == 5">
                    <label @click="updateFeedbackRate(feedback.id, 5)"></label>
                    <input type="radio" :checked="feedback.rate == 4">
                    <label @click="updateFeedbackRate(feedback.id, 4)"></label>
                    <input type="radio" :checked="feedback.rate == 3">
                    <label @click="updateFeedbackRate(feedback.id, 3)"></label>
                    <input type="radio" :checked="feedback.rate == 2">
                    <label @click="updateFeedbackRate(feedback.id, 2)"></label>
                    <input type="radio" :checked="feedback.rate == 1">
                    <label @click="updateFeedbackRate(feedback.id, 1)"></label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <textarea v-model="comment" class="form-control" placeholder="Write your comments" cols="10" rows="10"></textarea>
            </div>
            <div class="d-flex justify-content-center">
              <button @click="submitFeedback" :disabled="sending" class="btn btn-quiz px-4">
                <b-spinner v-if="sending" variant="light"></b-spinner>
                <span v-else class="text-white">
                  Send
                </span>
              </button>
            </div>
          </template>
        </div>
    </b-modal>
</template>

<script>
import { getFeedbackQuestions, submitFeedback } from "@/API/Quiz";
import { mapGetters } from 'vuex';

export default {
    name: "FeedbackModal",
    data() {
      return {
        loading: true,
        sending: false,
        feedbacks: [],
        comment: null,
      };
    },
    watch: {
      moduleID(ID) {
        this.comment = null;
        
        if(ID) {
          this.getFeedbackQuestions();
        }
      }
    },
    computed: {
      ...mapGetters({
        moduleID: 'quiz/currentModuleID',
        currentModule: 'quiz/currentModule'
      })
    },
    methods: {
      async getFeedbackQuestions() {
        try {
          this.loading = true;
          const res = await getFeedbackQuestions(this.moduleID);
          const feedbacks = res.data?.data?.feedBack || [];
          this.feedbacks = feedbacks.map(feedback => {
            feedback.rate = 1;
            return feedback;
          });
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      },
      updateFeedbackRate(feedbackID, value) {
        this.feedbacks = this.feedbacks.map(feedback => {

          if (feedback.id == feedbackID) {
            feedback.rate = value
          }

          return feedback;
        })
      },
      async submitFeedback() {
        const feedbackData = {
          comment: this.comment || "",
          feedback_id: []
        }

        this.feedbacks.forEach((feedback, index) => {
          feedbackData.feedback_id.push({
            id: feedback.id,
            rate: feedback.rate
          })
        });

        this.sending = true;
        try {
          await submitFeedback(feedbackData);

          if(!this.currentModule.is_user_updated) {
            this.$root.$emit("bv::show::modal", "update-data-modal");
            return;
          }

          const URL = `/quiz/${this.currentModule.quiz_code}?quiz_id=${this.currentModule.quiz_id}&partner_id=${this.currentModule.partner_id}&speciality_id=${this.currentModule.speciality_id}&verified=true`;

          this.$router.push(URL);
        } catch (error) {
          console.error(error);
           this.$notify('Unexpected Error!');
        } finally {
          this.sending = false;
        }
      },
    },
};
</script>

<style scoped lang="scss">
  textarea.form-control {
    height: 7rem;
    border-radius: 20px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: #F9F9F9;
    font-weight: normal;

    &::placeholder {
      color: #737373;
    }
  }

</style>
