<template>
    <b-modal
        id="sign-up-modal"
        static
        no-close-on-backdrop
        centered
        hide-footer
    >
        <template #modal-header="{ close }">
            <div></div>
            <b-button size="sm" @click="close()"> X </b-button>
        </template>
        <form class="row" @submit.prevent="signUp()">
            <div class="col-12" v-for="(error, index) in errors" :key="index">
                <b-alert class="p-2" show variant="danger">{{ error }}</b-alert>
            </div>
            <div class="col-lg-7">
                <h1 class="font-weight-600">Create An Account</h1>
                <p class="d-flex align-items-center mb-5">
                    <span>Already an user?</span>
                    <span
                        @click="
                            $bvModal.hide('sign-up-modal');
                            $bvModal.show('login-modal');
                        "
                        class="btn text-brand"
                    >
                        Sign In
                    </span>
                </p>

                <div class="form-group">
                    <input
                        v-model.trim="email"
                        type="text"
                        class="form-control"
                        :placeholder="$t('email')"
                    />
                </div>
                <div class="form-group">
                    <input
                        v-model.trim="password"
                        type="password"
                        class="form-control"
                        :placeholder="$t('password')"
                    />
                </div>
                <div class="mb-3">
                    <label class="control d-flex align-items-start">
                        <input type="checkbox" v-model="isTermsRead" />
                        <span class="checker">
                            <img
                                src="@/assets/imgs/check-icon.png"
                                alt="check"
                            />
                        </span>
                        <small class="d-flex flex-wrap align-items-center">
                            I agree to the
                            <button
                                v-b-modal.terms-modal
                                type="button"
                                class="btn p-0 text-brand mx-1"
                            >
                                Terms and Conditions
                            </button>
                        </small>
                    </label>
                </div>
                <div>
                    <button
                        type="submit"
                        :disabled="sending"
                        class="btn btn-quiz btn-block"
                    >
                        <b-spinner v-if="sending" variant="light"></b-spinner>
                        <span v-else class="text-white">Sign Up</span>
                    </button>
                </div>
            </div>
            <div class="col-lg-5 py-4 d-none d-lg-block">
                <img
                    src="@/assets/imgs/sign-up-vector.png"
                    class="mw-100"
                    alt="vector"
                />
            </div>
        </form>
    </b-modal>
</template>

<script>
import { signUpUser } from "@/API/auth";
export default {
    name: "SignUpModal",
    data() {
        return {
            email: null,
            password: null,
            isTermsRead: false,
            errors: [],
            sending: false,
        };
    },
    methods: {
        validateEmail() {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isValidEmail = re.test(String(this.email).toLowerCase());
            if (!isValidEmail) {
                this.errors.push(this.$t("emailError"));
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
                return false;
            }
        },
        signUp() {
            this.errors = [];

            this.validateEmail();

            if (!this.password || this.password?.length < 3)
                this.errors.push(this.$t("passwordError"));

            if (!this.isTermsRead)
                this.errors.push(this.$t("acceptTermsError"));

            // sign up
            if (!this.errors.length) {
                this.sending = true;

                const signUpData = {
                    email: this.email,
                    password: this.password,
                };

                signUpUser(signUpData).then((res) => {
                    // console.log(res);
                    this.sending = false;
                    if (res.status == 200) {
                        // console.log(res.data);
                        this.$store.commit(
                            "auth/UPDATEAUTHINFO",
                            res.data?.object
                        );

                        this.$router.push(`/verification?email=${this.email}`);
                        this.$notify(res.data.message);

                        this.$root.$emit("bv::hide::modal", "sign-up-modal");

                        return;
                    }

                    if (res?.status == 500) {
                        this.$root.$emit("bv::hide::modal", "sign-up-modal");
                        this.$router.push("/500");
                        return;
                    }

                    if (res?.data?.message) {
                        this.errors.push(res.data.message);
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                    }
                });
            }
        },
    },
    mounted() {
        this.$root.$on("bv::modal::hide", () => {
            this.email = null;
            this.password = null;
            this.errors = [];
        });
    },
};
</script>
