<template>
    <div>
        <b-modal
            id="forget-pass-modal"
            no-close-on-backdrop
            static
            centered
            hide-footer
        >
            <template #modal-header="{ close }">
                <div></div>
                <b-button size="sm" @click="close()"> X </b-button>
            </template>
            <form class="row" @submit.prevent="sendEmail">
                <div class="col-lg-7">
                    <h1 class="font-weight-600">Forgot Password</h1>
                    <p class="d-flex align-items-center" :class="[emailError || successMessage ? 'mb-4' : 'mb-5']">
                        Enter your Email Address
                    </p>
                    <div v-if="emailError" class="mb-1">
                        <b-alert class="p-2" show variant="danger">
                            {{ emailError }}
                        </b-alert>
                    </div>
                    <div v-if="successMessage">
                        <b-alert class="p-2" show variant="success">
                            {{ successMessage }}
                        </b-alert>
                    </div>
                    <div v-if="!successMessage">
                        <div class="form-group">
                            <input
                                v-model.trim="email"
                                type="text"
                                class="form-control"
                                :placeholder="$t('email')"
                            />
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-quiz btn-block">
                                <b-spinner
                                    v-if="sending"
                                    variant="light"
                                ></b-spinner>
                                <span v-else class="text-white">Send</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 d-none d-lg-block">
                    <img
                        src="@/assets/imgs/forget-vector.png"
                        class="mw-100"
                        alt="vector"
                    />
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import { resetPassword } from "@/API/auth";

export default {
    name: "ForgetPassModal",
    data() {
        return {
            email: null,
            sending: false,
            emailError: null,
            successMessage: null
        };
    },
    methods: {
        verifyEmail() {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isValidEmail = re.test(String(this.email).toLowerCase());

            if (isValidEmail) this.emailError = null
            else this.emailError = this.$t('emailError')

            return isValidEmail
        },
        sendEmail() {
            if (!this.verifyEmail()) return;

            this.sending = true;
            this.emailError = null;

            const data = {email: this.email}
            resetPassword(data).then(res => {
                if (res.status == 200) {
                    // console.log(res.data);
                    // this.$notify(res.data.message);
                    // this.$root.$emit("bv::hide::modal", "forget-pass-modal");
                    this.successMessage = this.$t('emailResetPassword')
                    return;
                }

                if (res.data?.message) {
                    this.emailError = res.data.message
                }

                if (res?.message) {
                    this.emailError = res.message
                }
            })
            .finally(() => this.sending = false)

        },
    },
    mounted() {
        this.$root.$on("bv::modal::hide", () => {
            this.email = null;
            this.emailError = null;
            this.successMessage = null;
            this.sending = false;
        });
    },
};
</script>
