<template>
    <b-modal id="restricted-modal" no-close-on-backdrop static centered hide-footer>
        <template #modal-header="{ close }">
            <div></div>
            <b-button size="sm" @click="close()"> X </b-button>
        </template>
        <div class="">
          <div class="d-flex align-items-center mb-3">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="29.25" viewBox="0 0 31.5 29.25">
                <path d="M2.25,32.625h31.5L18,3.375Zm17.438-4.5H16.313V24.75h3.375Zm0-5.625H16.313V15.75h3.375Z" transform="translate(-2.25 -3.375)" fill="#ffd064"/>
              </svg>
            </span>
            <h5 class="mx-2 mb-0">Special Module</h5>
          </div>
          <p class="mb-4">
            This Module is closed and must be requested from the owner
          </p>
          <div class="d-flex justify-content-center">
            <button type="button"
            @click="sendToOwner"
              :disabled="sending"
              class="btn btn-quiz"
            >
                <b-spinner v-if="sending" variant="light"></b-spinner>
                <span v-else class="text-white">
                  Send to Owner
                </span>
            </button>
          </div>
        </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendToOwner } from "@/API/modules";

export default {
    name: "RestrictedModuleModal",
    data() {
        return {
          sending: false
        };
    },
    computed: {
      ...mapGetters({
        moduleID: 'restrictedModule'
      })
    },
    methods: {
      async sendToOwner() {
        try {
          this.sending = true;
          const res = await sendToOwner(this.moduleID);
          if(res.data?.status) {
            this.$notify('Sent Successfully.');
          } else {
            this.$notify(res.data?.message);
          }
          
        } catch (error) {
          console.error(error);
        } finally {
          this.sending = false;
          this.$root.$emit("bv::hide::modal", "restricted-modal");
        }
      }
    },
};
</script>
