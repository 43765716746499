import { API, logError } from "./index";

const terms = async () => {
    try {
        return await API().get(`/terms`);
    } catch (error) {
        return logError(error);
    }
}

export { terms }