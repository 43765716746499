<template>
    <b-modal id="login-modal" no-close-on-backdrop static centered hide-footer>
        <template #modal-header="{ close }">
            <div></div>
            <b-button size="sm" @click="close()"> X </b-button>
        </template>
        <form class="row" @submit.prevent="login()">
            <div class="col-12" v-for="(error, index) in errors" :key="index">
                <b-alert class="p-2" show variant="danger">{{ error }}</b-alert>
            </div>
            <div v-if="showVerifyOption" class="col-12">
                <b-alert class="p-2 d-flex align-items-center" show variant="danger">
                    Go to 
                    <span @click="verificationPage" class="btn p-1 cursor-pointer">
                        Verification Page
                    </span>
                </b-alert>
            </div>
            <div class="col-lg-7">
                <h1 class="font-weight-600">Sign In</h1>
                <p class="d-flex flex-wrap align-items-center mb-5">
                    <span>I don't have user</span>
                    <span
                        @click="
                            $bvModal.hide('login-modal');
                            $bvModal.show('sign-up-modal');
                        "
                        class="btn text-brand"
                    >
                        Create An Account
                    </span>
                </p>
                <div class="form-group">
                    <input
                        v-model.trim="email"
                        type="text"
                        class="form-control"
                        :placeholder="$t('email')"
                    />
                </div>
                <div class="form-group">
                    <input
                        v-model.trim="password"
                        type="password"
                        class="form-control"
                        :placeholder="$t('password')"
                    />
                </div>
                <div class="form-group">
                    <button
                        type="submit"
                        :disabled="sending"
                        class="btn btn-quiz btn-block"
                    >
                        <b-spinner v-if="sending" variant="light"></b-spinner>
                        <span v-else class="text-white">Sign In</span>
                    </button>
                </div>
                <div>
                    <small
                        @click="
                            $bvModal.hide('login-modal');
                            $bvModal.show('forget-pass-modal');
                        "
                        class="btn"
                        >Forgot password</small
                    >
                </div>
            </div>
            <div class="col-lg-5 py-4 d-none d-lg-block">
                <img
                    src="@/assets/imgs/sign-in-vector.png"
                    class="mw-100"
                    alt="vector"
                />
            </div>
        </form>
    </b-modal>
</template>

<script>
import { loginUser } from "@/API/auth";
export default {
    name: "LoginModal",
    data() {
        return {
            email: null,
            password: null,
            errors: [],
            sending: false,
            showVerifyOption: false
        };
    },
    methods: {
        validateEmail() {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isValidEmail = re.test(String(this.email).toLowerCase());
            if (!isValidEmail) {
                this.errors.push(this.$t("validEmailError"));
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
                return false;
            }
        },
        login() {
            this.errors = [];
            this.showVerifyOption = false

            this.validateEmail();

            if (!this.password) this.errors.push(this.$t("passwordError"));

            // login
            if (!this.errors.length) {
                this.sending = true;

                const loginData = {
                    email: this.email,
                    password: this.password,
                };

                loginUser(loginData).then((res) => {
                    // console.log(res.data);
                    this.sending = false;
                    if (res.status == 200) {
                        // console.log(res.data);
                        this.$store.commit( "auth/UPDATEAUTHINFO", res.data?.object );
                        this.$router.push("/dashboard");

                        // // Get user notifications
                        // this.$store.dispatch('firebase/getUserNotifications');
                        
                        // // Fire Firebase messaging for notifications
                        // this.$store.dispatch('firebase/updateMessagingFCMToken', this);

                        this.$notify(res.data.message);
                        this.$root.$emit("bv::hide::modal", "login-modal");

                        return;
                    }

                    if (res?.status == 500) {
                        this.$root.$emit("bv::hide::modal", "login-modal");
                        this.$router.push("/500");
                        return;
                    }

                    // console.log(res);

                    if (res?.data?.message) {
                        if (res?.data?.process_code == "IN_ACTIVE_ACCOUNT") {
                            this.showVerifyOption = true
                        }

                        this.errors.push(res.data.message);
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                    }
                });
            }
        },
        verificationPage() {
            this.errors = []
            this.validateEmail()
            if (this.email) {
                this.$router.push(`/verification?email=${this.email}`)
                this.$root.$emit("bv::hide::modal", "login-modal");
            }
        }
    },
    mounted() {
        this.$root.$on("bv::modal::hide", () => {
            this.email = null;
            this.password = null;
            this.errors = [];
            this.sending = false;
            this.showVerifyOption = false;
        });
    },
};
</script>
