export default {
    home: "الرئيسية",
    firstname: "الاسم الأول",
    lastname: "الاسم الأخير",
    email: "البريد الإلكتروني",
    country: "الدولة",
    specialty: "التخصص",
    subSpecialty: "التخصص الفرعي",
    licenceNO: "رقم الترخيص",
    hospital: "المستشفى",
    fNameError: "أدخل الاسم الأول",
    lNameError: "أدخل الاسم الأخير",
    emailError: "أدخل بريدا إلكترونيا صحيحا",
    countryError: "اختر دولتك",
    specialtyError: "اختر التخصص",
    subSpecialtyError: "اختر التخصص الفرعي",
    licenceError: "أدخل رقم الترخيص",
    hospitalError: "أدخل اسم المستشفى",
    password: "كلمة المرور",
    requiredErorr: "قم بالأجابة على جميع الأسئلة المطلوبة",
    validEmailError: "أدخل أيميلا صحيحا",
    passwordError: "أدخل كلمة المرور",
    acceptTermsError: "تأكد أولا من قراءتك للشروط والأحكام",
    disconnected: "أنقطع الاتصال بالأنترنت",
    connected: "تم إعادة الأتصال بالأنترنت",
    Male: "ذكر",
    Female: "أنثى",
    noMobileRegistered: "لم يتم تسجيل هاتف بعد.",
    verificationCode: "كود التفعيل",
    nameRequired: "أدخل اسمك",
    titleRequired: "أدخل عنوان الرسالة",
    mobileRequired: "أدخل الهاتف الخاص بك",
    bodyRequired: "يمكنك كتابة نص الرسالة قبل الإرسال",
    fnameRequired: "الاسم الأول مطلوب",
    lnameRequired: "الاسم الأخير مطلوب",
    confirm: "تأكيد",
    cancel: "إلغاء",
    logoutConfirm: "هل تريد الخروج بالفعل؟",
    contact: "اتصل بنا",
    contactUs: "اتصل بنا",
    contactInfo: "وسائل الاتصال",
    name: "الاسم",
    yourname: "أسمك",
    msgTitle: "عنوان الرسالة",
    yourEmail: "أيميلك",
    yourMobile: "رقم هاتفك",
    mobile: "هاتفك",
    yourMsg: "رسالتك",
    msg: "الرسالة",
    sendMsg: "إرسال رسالة",
    about: "من نحن",
    aboutUs: "من نحن",
    aboutDLP: "عن DLP",
    partners: "الشركاء",
    events: "المناسبات",
    joinUs: "انضم لنا",
    signIn: "الدخول",
    logout: "الخروج",
    myAccount: "حسابي",
    completeProfile: "إكمال بيانات الحساب",
    search: "بحث",
    date: "التاريخ",
    noResults: "لا توجد نتائج",
    next: "التالي",
    prev: "السابق",
    termsAndConditions: "الشروط والأحكام",
    noModulesFound: "No modules found for this partner",
    enterCorrectCode: "أدخل الكود الصحيح!",
    emailResetPassword: "We've sent a temp. password to your email, after login you can update your new password.",
    noNotifications: "No Notifications Yet",
    restrictedModule: "You are not authorized to see this module",
    fullName: "Fullname",
    fullNameError: "Enter your Fullname",
    addedToFavorite: 'تمت الإضافة لقائمة مفضلتك',
    removedToFavorite: 'تم الحذف من المفضلة',
    
}