<template>
    <div>
        <div v-if="loading" class="d-flex justify-content-center align-items-center py-5 my-sm-5">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="80" height="80" viewBox="0 0 50 50" enable-background="new 0 0 40 40" xml:space="preserve"><path opacity="0.2" fill="#333333" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/><path fill="#333333" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                C22.32,8.481,24.301,9.057,26.013,10.047z">
                <animateTransform attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 20 20"
                to="360 20 20"
                dur="0.5s"
                repeatCount="indefinite"/>
                </path>
            </svg>
        </div>
        <template v-else>
            <div class="module-head">
                <div class="container">
                    <div
                        class="
                            d-flex
                            flex-column flex-sm-row
                            align-items-center
                            justify-content-between
                        "
                    >
                        <div
                            class="
                                d-flex
                                align-items-center
                                justify-content-between justify-content-sm-start
                                mb-3 mb-sm-0
                                w-100 w-sm-auto
                            "
                        >
                            <div>
                                <p class="text-gray mb-0">Issue date</p>
                                <span>
                                    <!-- 04/20/2021 -->
                                    {{ $moment(module.issue_date).format("DD/MM/YYYY") }}
                                </span>
                            </div>
                            <div class="d-flex align-items-center">
                                <!-- like -->
                                <button 
                                :class="{active : module.is_favorite}"
                                @click="makeModuleFav"
                                class="btn mx-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 36.777 32.306"
                                    >
                                        <path
                                            d="M32.3,4.388a9.555,9.555,0,0,0-13.038.95L17.887,6.757,16.51,5.338a9.554,9.554,0,0,0-13.038-.95,10.033,10.033,0,0,0-.692,14.526L16.3,32.874a2.19,2.19,0,0,0,3.165,0l13.52-13.96A10.026,10.026,0,0,0,32.3,4.388Z"
                                            transform="translate(0.503 -1.744)"
                                            fill="none"
                                            stroke="#007ec6"
                                            stroke-width="1"
                                            class="stroke"
                                        />
                                    </svg>
                                </button>
                                <!-- share -->
                                <!-- <button class="btn">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="28"
                                        viewBox="0 0 28 31"
                                    >
                                        <g transform="translate(-4 -2.5)">
                                            <path
                                                d="M31.5,7.5A4.5,4.5,0,1,1,27,3,4.5,4.5,0,0,1,31.5,7.5Z"
                                                fill="none"
                                                stroke="#007ec6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1"
                                                class="stroke"
                                            />
                                            <path
                                                d="M13.5,18A4.5,4.5,0,1,1,9,13.5,4.5,4.5,0,0,1,13.5,18Z"
                                                fill="none"
                                                stroke="#007ec6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1"
                                                class="stroke"
                                            />
                                            <path
                                                d="M31.5,28.5A4.5,4.5,0,1,1,27,24,4.5,4.5,0,0,1,31.5,28.5Z"
                                                fill="none"
                                                stroke="#007ec6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1"
                                                class="stroke"
                                            />
                                            <path
                                                d="M12.885,20.265l10.245,5.97"
                                                fill="none"
                                                stroke="#007ec6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1"
                                            />
                                            <path
                                                d="M23.115,9.765l-10.23,5.97"
                                                fill="none"
                                                stroke="#007ec6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1"
                                            />
                                        </g>
                                    </svg>
                                </button> -->
                            </div>
                        </div>
                        <div
                            v-if="module.payment"
                            class="
                                d-flex
                                align-items-center
                                justify-content-between justify-content-sm-end
                                w-100 w-sm-auto
                            "
                        >
                            <div class="mx-sm-2 mx-md-4">
                                <span class="text-gray mx-2">Price</span>
                                <span class="mx-1 text-price">
                                    {{ module.payment }} 
                                </span>
                            </div>
                            <small class="owned" v-if="module.owned">Owend</small>
                            <button v-else @click="buyCurrentModule" :disabled="buying"
                            class="btn btn-brand font-weight-normal" :class="{'px-5 py-1' : buying}">
                                <b-spinner v-if="buying" variant="light"></b-spinner>
                                <span v-else class="text-white">Buy Now</span>
                            </button>
                        </div>
                        <div v-else class="d-flex align-items-center justify-content-between justify-content-sm-end w-100 w-sm-auto px-2">
                            <span class="text-success">Free</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- module content -->
            <div class="module-content">
                <div class="container">
                    <div class="d-flex flex-column flex-lg-row">
                        <!-- details -->
                        <div class="module-details order-2 order-lg-2">
                            <div class="row mb-5">
                                <div
                                    class="
                                        col-xl-6
                                        mb-4 mb-xl-0
                                        d-flex
                                        justify-content-center
                                    "
                                >
                                    <img
                                        class="mw-100"
                                        style="border-radius: 15px"
                                        :src="module.image"
                                        :alt="module.name "
                                    />
                                </div>
                                <div class="col-xl-6">
                                    <h4 class="font-weight-600 mb-2 text-capitalize">
                                        {{ module.name }}
                                    </h4>
                                    <span class="d-inline-block text-gray mb-3">
                                        {{ module.speciality }}
                                    </span>
                                    <pre>{{ module.description }}</pre>
                                </div>
                            </div>
                            <div class="content-type">
                                <!-- article -->
                                <div v-if="module.module_type == 'article'">
                                    <div :id="`art_${index}`" :data-index="index" v-for="(article, index) in module.material" :key="index">
                                        <h4 v-if="article.title" :class="{'mt-3' : index != 0}">
                                            {{ article.title }}
                                        </h4>
                                        <div v-if="article.image">
                                            <img :src="article.image" style="max-width: 100%;">
                                        </div>
                                        <pre>
                                            {{ article.body }}
                                        </pre>
                                        <div ></div>
                                    </div>
                                </div>

                                <!-- video -->
                                <div v-if="module.module_type == 'video'">
                                    <div class="row pt-2">
                                        <template v-for="(video, index) in module.material">
                                            <div
                                                :key="index"
                                                class="col-md-6 col-lg-12 col-xl-6 mb-4"
                                                v-if="videoID(video.body)"
                                            >
                                                <div class="video-card">
                                                    <div @click="displayVideo(videoID(video.body))" class="overlay"></div>
                                                    <div class="video-card-img">
                                                        <vue-plyr :ref="`player_${index + 1}`">
                                                            <div data-plyr-provider="vimeo" :data-plyr-embed-id="videoID(video.body)"></div>
                                                        </vue-plyr>
                                                    </div>
                                                    <div
                                                        class="
                                                            video-card-body
                                                            d-flex
                                                            align-items-center
                                                            justify-content-between
                                                            bg-gray
                                                        "
                                                    >
                                                        <h6 class="title mb-0">
                                                            <span class="video-count" v-if="module.material.length">
                                                                {{ index + 1 }}
                                                            </span>
                                                            <span class="font-weight-600 text-capitalize">
                                                            {{ video.title }}
                                                            </span>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                                <!-- ppt -->
                                <div v-if="module.module_type == 'ppt'" class="pt-3">
                                    <!-- {{ module.material }} -->
                                    <div :id="`art_${index}`" class="mb-3" v-for="(ppt, index) in module.material" :key="index">
                                        <h4 v-if="ppt.title" class="mb-3 text-capitalize">
                                            {{ ppt.title }}
                                        </h4>
                                        <!-- <object :data="`https://docs.google.com/gview?url=${ppt.body}&embedded=true`" />  -->
                                        <iframe :id="`el_${index}`" width="100%" height="400" frameborder="0" :src="`https://docs.google.com/gview?url=${ppt.body}&embedded=true`"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- sidebar -->
                        <aside
                            class="
                                module-sidebar
                                order-1 order-lg-2
                                mb-4 mb-lg-0
                                d-flex
                                flex-column flex-md-row flex-lg-column
                            "
                        >
                            <div class="pr-md-3 pr-lg-0">
                                <!-- pills info -->
                                <div class="d-flex w-100 mb-3">
                                    <div v-if="getMaterialLength(module.material)" class="summary pill d-flex flex-column align-items-center justify-content-center bg-gray">
                                        <span class="num">
                                            {{ getMaterialLength(module.material) }}
                                        </span>
                                        <small>File</small>
                                    </div>
                                    <div v-if="module.cme" class="summary pill d-flex flex-column align-items-center justify-content-center bg-gray">
                                        <span class="num">{{ module.cme }}</span>
                                        <small>CME</small>
                                    </div>
                                    <div v-if="module.duration" class="summary pill d-flex flex-column align-items-center justify-content-center bg-gray">
                                        <span class="num">{{ module.duration }}</span>
                                        <small>Duration</small>
                                    </div>
                                    <div class="summary pill bg-gray">
                                        <!-- article -->
                                        <div v-if="module.module_type == 'article'" class="d-flex flex-column align-items-center justify-content-center">
                                            <span class="num">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="22"
                                                    height="20"
                                                    viewBox="0 0 24.687 22.63"
                                                >
                                                    <g transform="translate(0 -1)">
                                                        <path
                                                            d="M11.458,8H5.8A1.8,1.8,0,0,0,4,9.8v5.657a1.8,1.8,0,0,0,1.8,1.8h5.657a1.8,1.8,0,0,0,1.8-1.8V9.8A1.8,1.8,0,0,0,11.458,8ZM5.8,9.543h5.657a.257.257,0,0,1,.257.257v2.659a1.851,1.851,0,0,0-2.434.335l-.534.668a.237.237,0,0,1-.386.02,1.805,1.805,0,0,0-2.551,0l-.267.267V9.8A.257.257,0,0,1,5.8,9.543Z"
                                                            transform="translate(0.115 0.2)"
                                                            fill="red"
                                                        />
                                                        <path
                                                            d="M21.6,1H3.086A3.09,3.09,0,0,0,0,4.086V20.544A3.09,3.09,0,0,0,3.086,23.63H21.6a3.09,3.09,0,0,0,3.086-3.086V4.086A3.09,3.09,0,0,0,21.6,1Zm0,20.573H3.086a1.03,1.03,0,0,1-1.029-1.029V6.143H22.63v14.4A1.03,1.03,0,0,1,21.6,21.573Z"
                                                            fill="red"
                                                        />
                                                        <path
                                                            d="M19.143,12.057H15.029a1.029,1.029,0,1,1,0-2.057h4.115a1.029,1.029,0,1,1,0,2.057Z"
                                                            transform="translate(0.401 0.258)"
                                                            fill="red"
                                                        />
                                                        <path
                                                            d="M19.143,16.057H15.029a1.029,1.029,0,1,1,0-2.057h4.115a1.029,1.029,0,1,1,0,2.057Z"
                                                            transform="translate(0.401 0.372)"
                                                            fill="red"
                                                        />
                                                        <path
                                                            d="M19.429,20.057H5.029a1.029,1.029,0,0,1,0-2.057h14.4a1.029,1.029,0,0,1,0,2.057Z"
                                                            transform="translate(0.115 0.487)"
                                                            fill="red"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                            <small>Article</small>
                                        </div>

                                        <!-- Video -->
                                        <div v-if="module.module_type == 'video'" class="d-flex flex-column align-items-center justify-content-center">
                                            <span class="num">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="28"
                                                    height="22"
                                                    viewBox="0 0 33.944 22.629"
                                                >
                                                    <path
                                                        d="M19.813,4.5h-17A2.817,2.817,0,0,0,0,7.317v17a2.817,2.817,0,0,0,2.817,2.817h17a2.817,2.817,0,0,0,2.817-2.817v-17A2.817,2.817,0,0,0,19.813,4.5ZM30.974,6.722l-6.459,4.455v9.276L30.974,24.9a1.89,1.89,0,0,0,2.97-1.52V8.242A1.89,1.89,0,0,0,30.974,6.722Z"
                                                        transform="translate(0 -4.5)"
                                                        fill="red"
                                                    />
                                                </svg>
                                            </span>
                                            <small>Video</small>
                                        </div>
                                        
                                        <!-- Presintaion -->
                                        <div v-if="module.module_type == 'ppt'"
                                        class="d-flex flex-column align-items-center justify-content-center">
                                            <span class="num">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="22.841"
                                                    height="23.747"
                                                    viewBox="0 0 22.841 23.747"
                                                >
                                                    <g transform="translate(-11.228)">
                                                        <path
                                                            d="M34.069,3.51a1.527,1.527,0,0,0-1.525-1.525H25.355c-.032,0-.063,0-.094.005V0L11.228,1.873V21.826L25.26,23.747V21.7c.032,0,.063,0,.094,0h7.189a1.527,1.527,0,0,0,1.525-1.525ZM14.147,12.723a1.3,1.3,0,0,1-.973.347,1.454,1.454,0,0,1-.226-.016v1.174l-.644-.02v-3.2a4.761,4.761,0,0,1,.883-.1,1.3,1.3,0,0,1,.915.236,1,1,0,0,1,.328.772A1.142,1.142,0,0,1,14.147,12.723Zm2.775,0a1.484,1.484,0,0,1-1.08.366,1.709,1.709,0,0,1-.251-.017v1.236l-.713-.022V10.922a5.532,5.532,0,0,1,.978-.106,1.5,1.5,0,0,1,1.015.247,1.029,1.029,0,0,1,.364.814A1.17,1.17,0,0,1,16.922,12.723ZM20.268,11.4l-1.026.024v2.995l-.825-.025V11.442l-.946.022v-.68l2.8-.1Zm2.7,3.131-.377-.721c-.153-.275-.251-.479-.367-.705h-.012c-.085.224-.188.425-.314.693l-.334.691-1.015-.031,1.139-1.917-1.1-1.867,1.022-.036.353.695c.121.233.212.422.308.64h.013c.1-.25.177-.425.281-.654l.352-.727,1.1-.039-1.2,1.962,1.264,2.051ZM33.2,20.177a.654.654,0,0,1-.654.654H25.355a.633.633,0,0,1-.094-.009v-8.9a3.987,3.987,0,0,0,6.207-.389L28.189,9.477,25.26,10.761v-.476l2.675-1.173V5.251a3.985,3.985,0,0,0-2.675,1.29V2.866a.64.64,0,0,1,.094-.01h7.189a.654.654,0,0,1,.654.654V20.177Z"
                                                            transform="translate(0)"
                                                            fill="red"
                                                        />
                                                        <path
                                                            d="M54.135,284.379a1.039,1.039,0,0,0-.274.036v.97a.9.9,0,0,0,.221.019.521.521,0,0,0,.578-.547A.452.452,0,0,0,54.135,284.379Z"
                                                            transform="translate(-40.913 -272.906)"
                                                            fill="red"
                                                        />
                                                        <path
                                                            d="M119.673,282.731a1.2,1.2,0,0,0-.3.039v1.021a1.037,1.037,0,0,0,.245.02.566.566,0,0,0,.641-.576C120.256,282.909,120.043,282.722,119.673,282.731Z"
                                                            transform="translate(-103.778 -271.324)"
                                                            fill="red"
                                                        />
                                                        <path
                                                            d="M438.07,128.806v3.831l3.33,2.089a3.988,3.988,0,0,0-3.33-5.92Z"
                                                            transform="translate(-409.621 -123.609)"
                                                            fill="red"
                                                        />
                                                        <rect
                                                            width="4.357"
                                                            height="0.65"
                                                            transform="translate(25.878 14.75)"
                                                            fill="red"
                                                        />
                                                        <rect
                                                            width="4.357"
                                                            height="0.651"
                                                            transform="translate(25.878 16.405)"
                                                            fill="red"
                                                        />
                                                        <rect
                                                            width="4.357"
                                                            height="0.651"
                                                            transform="translate(25.878 18.035)"
                                                            fill="red"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                            <small>PPT</small>
                                        </div>
                                    </div>
                                </div>

                                <!-- bio -->
                                <div
                                    class="
                                        summary
                                        bg-gray
                                        d-flex
                                        align-items-center
                                        owner
                                        mb-3
                                    "
                                >
                                    <div>
                                        <img :src="module.speak_info_image" 
                                        :alt="module.speak_info_name" />
                                    </div>
                                    <div>
                                        <div class="d-flex align-items-center mb-2">
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 20.002 20"
                                                >
                                                    <g transform="translate(0.001)">
                                                        <path
                                                            d="M14.542,22.667H7.458a.625.625,0,0,1-.617-.53L6.008,16.72A.624.624,0,0,1,6.625,16h8.75a.625.625,0,0,1,.618.721l-.833,5.417a.625.625,0,0,1-.617.529Z"
                                                            transform="translate(-1 -2.667)"
                                                            fill="#007ec6"
                                                        />
                                                        <ellipse
                                                            cx="3"
                                                            cy="3.5"
                                                            rx="3"
                                                            ry="3.5"
                                                            transform="translate(6.999)"
                                                            fill="#007ec6"
                                                        />
                                                        <path
                                                            d="M19.347,11.833a6.057,6.057,0,0,0-2.682-4.592,1.263,1.263,0,1,0-.626,1.086A4.8,4.8,0,0,1,18.1,11.833H16.616A3.963,3.963,0,0,0,12.708,8.5H7.291a3.963,3.963,0,0,0-3.908,3.333H.625a.635.635,0,0,0-.592.842L1.7,17.258a.633.633,0,0,0,.592.408H3.725l-.367-2.358a2.3,2.3,0,0,1,.525-1.842,2.335,2.335,0,0,1,1.742-.8h8.75a2.292,2.292,0,0,1,2.267,2.642l-.367,2.358h1.433a.633.633,0,0,0,.592-.408l1.667-4.583a.633.633,0,0,0-.619-.842Z"
                                                            transform="translate(0 -1)"
                                                            fill="#007ec6"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>
                                            <h6 class="mx-2 mb-0 font-weight-600">
                                                {{ module.speak_info_name }}
                                            </h6>
                                        </div>
                                        <p class="mb-0">
                                            <small v-if="!isMoreText" class="text-break">
                                                {{ module.speak_info_bio ? textSummary(module.speak_info_bio, 75) : '' }}
                                                <button @click="isMoreText = true" v-if="bioLength > 75"
                                                    class="btn p-0 text-brand font-weight-normal">
                                                    More
                                                </button>
                                            </small>
                                            <small v-else class="text-break">
                                                {{ module.speak_info_bio }}
                                                <button @click="isMoreText = false" v-if="bioLength > 30"
                                                    class="btn p-0 text-brand font-weight-normal">
                                                    Less
                                                </button>
                                            </small>
                                        </p>
                                    </div>
                                </div>

                                <!-- cat -->
                                <div
                                    class="
                                        summary
                                        bg-gray
                                        px-3
                                        py-2
                                        mb-3
                                    "
                                >
                                    <router-link class="d-flex
                                        align-items-center" :to="`/therapeutic-area/${module.speciality_id}`">
                                        <img
                                            class="rounded-circle img-circle mr-3"
                                            width="60"
                                            height="60"
                                            :src="module.speciality_image"
                                            :alt="module.speciality"
                                        />
                                        <span class="h6 font-weight-600">
                                            {{ module.speciality }}
                                        </span>
                                    </router-link>
                                </div>

                                <!-- quiz progress -->
                                <div v-if="module.quiz_code" class="mb-2">
                                    <div v-if="module.quiz_restricted">
                                        <div class="
                                                d-flex
                                                align-items-center
                                                justify-content-between
                                            "
                                        >
                                            <small class="font-weight-600">Quiz</small>
                                            <small class="text-gray">
                                                <span class="text-gray">
                                                    {{ progressValue }}
                                                </span>%
                                            </small>
                                        </div>
                                        <div class="progress">
                                            <div
                                                :style="`width: ${progressValue}%`"
                                                :class="{'success' : progressValue >= quizProgressRequire}"
                                                class="progress-value"
                                            ></div>
                                        </div>
                                    </div>
                                    <!-- <div v-else class="p-1">
                                        <small class="font-weight-bold">
                                            You are not authorized to take this quiz
                                        </small>
                                    </div> -->
                                </div>

                                <div v-if="module.quiz_code"
                                    class="
                                        summary
                                        p-0
                                        bg-gray
                                        mb-3
                                        d-flex
                                        align-items-center
                                        justify-content-between
                                    "
                                >
                                    <div
                                        class="
                                            d-flex
                                            align-items-center
                                            mr-2
                                            py-1
                                            px-2
                                        "
                                    >
                                        <span class="mr-2 badge-cme">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="39.648"
                                                height="39.507"
                                                viewBox="0 0 39.648 39.507"
                                            >
                                                <g transform="translate(-54.702)">
                                                    <g
                                                        transform="translate(54.701 0)"
                                                    >
                                                        <g
                                                            transform="translate(0 0)"
                                                        >
                                                            <path
                                                                d="M76.558.708a3.271,3.271,0,0,0,3,.561,3.27,3.27,0,0,1,3.791,1.469,3.271,3.271,0,0,0,2.6,1.608,3.271,3.271,0,0,1,3,2.739,3.271,3.271,0,0,0,1.841,2.438,3.271,3.271,0,0,1,1.812,3.639,3.271,3.271,0,0,0,.836,2.938,3.271,3.271,0,0,1,.375,4.048,3.27,3.27,0,0,0-.282,3.042,3.271,3.271,0,0,1-1.113,3.91,3.271,3.271,0,0,0-1.362,2.734,3.271,3.271,0,0,1-2.45,3.244,3.271,3.271,0,0,0-2.257,2.058,3.27,3.27,0,0,1-3.457,2.14,3.271,3.271,0,0,0-2.848,1.1,3.271,3.271,0,0,1-4,.747,3.271,3.271,0,0,0-3.055,0,3.27,3.27,0,0,1-4-.747,3.271,3.271,0,0,0-2.848-1.1,3.271,3.271,0,0,1-3.457-2.14,3.271,3.271,0,0,0-2.257-2.058,3.27,3.27,0,0,1-2.45-3.244A3.271,3.271,0,0,0,56.628,27.1a3.27,3.27,0,0,1-1.113-3.91,3.27,3.27,0,0,0-.282-3.042,3.271,3.271,0,0,1,.375-4.048,3.271,3.271,0,0,0,.836-2.938,3.27,3.27,0,0,1,1.812-3.639A3.271,3.271,0,0,0,60.1,7.086a3.271,3.271,0,0,1,3-2.739,3.27,3.27,0,0,0,2.6-1.608A3.271,3.271,0,0,1,69.49,1.27a3.27,3.27,0,0,0,3-.561,3.271,3.271,0,0,1,4.066,0Z"
                                                                transform="translate(-54.701 0)"
                                                                fill="#e8ae4d"
                                                            />
                                                            <g
                                                                transform="translate(5.396 5.325)"
                                                            >
                                                                <path
                                                                    d="M132.84,69.075A15.178,15.178,0,0,0,122.56,54.71a14.429,14.429,0,0,0,0,28.731A15.177,15.177,0,0,0,132.84,69.075Z"
                                                                    transform="translate(-109.489 -54.647)"
                                                                    fill="#ffe07d"
                                                                />
                                                                <path
                                                                    d="M226.583,54.07a14.474,14.474,0,0,0-3.213.36,14.432,14.432,0,0,1,0,28.138,14.43,14.43,0,1,0,3.213-28.5Z"
                                                                    transform="translate(-212.155 -54.07)"
                                                                    fill="#ffd064"
                                                                />
                                                                <text
                                                                    transform="translate(2.83 18.343)"
                                                                    fill="#df9d2e"
                                                                    font-size="11"
                                                                    font-family="NotoSans-Bold, Noto Sans"
                                                                    font-weight="700"
                                                                >
                                                                    <tspan
                                                                        x="0"
                                                                        y="0"
                                                                    >
                                                                        CME
                                                                    </tspan>
                                                                    <tspan y="0">
                                                                        ‏
                                                                    </tspan>
                                                                </text>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                        <div class="text-center">
                                            <h6 class="mb-0 font-weight-600">
                                                CME
                                            </h6>
                                            <small>Accredited</small>
                                        </div>
                                    </div>
                                    <button
                                        :disabled="!isQuizVerified"
                                        @click="takeQuiz()"
                                        class="btn btn-brand d-flex px-3 take-quiz align-items-center justify-content-between">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 21.209 28.279"><path d="M18.558,3.535H14.139a3.535,3.535,0,1,0-7.07,0H2.651A2.652,2.652,0,0,0,0,6.186V25.628a2.652,2.652,0,0,0,2.651,2.651H18.558a2.652,2.652,0,0,0,2.651-2.651V6.186A2.652,2.652,0,0,0,18.558,3.535ZM5.3,23.418a1.326,1.326,0,1,1,1.326-1.326A1.322,1.322,0,0,1,5.3,23.418Zm0-5.3A1.326,1.326,0,1,1,6.628,16.79,1.322,1.322,0,0,1,5.3,18.116Zm0-5.3a1.326,1.326,0,1,1,1.326-1.326A1.322,1.322,0,0,1,5.3,12.814Zm5.3-10.6A1.326,1.326,0,1,1,9.279,3.535,1.322,1.322,0,0,1,10.6,2.209Zm7.07,20.325a.443.443,0,0,1-.442.442H9.279a.443.443,0,0,1-.442-.442v-.884a.443.443,0,0,1,.442-.442h7.953a.443.443,0,0,1,.442.442Zm0-5.3a.443.443,0,0,1-.442.442H9.279a.443.443,0,0,1-.442-.442v-.884a.443.443,0,0,1,.442-.442h7.953a.443.443,0,0,1,.442.442Zm0-5.3a.443.443,0,0,1-.442.442H9.279a.443.443,0,0,1-.442-.442v-.884a.443.443,0,0,1,.442-.442h7.953a.443.443,0,0,1,.442.442Z" fill="#fff"/></svg>
                                        </span>
                                        <span class="text-white font-weight-normal">
                                            Take Quiz
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <!-- module-add -->
                            <div v-if="advertisement" class="mb-3 add">
                                <img
                                    width="100%"
                                    :src="advertisement"
                                    alt="module adds"
                                />
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import { moduleVisited } from "@/API/my-account";
import { 
    singleModule, 
    advertisementModule,
    sendProgressPercentage,
    materialVisited,
    buyModule
} from "@/API/modules";
import { storeFavorite } from "@/API/favorite";
import textSummary from "@/mixins/textSummary";

export default {
    name: "Module",
    mixins: [textSummary],
    data() {
        return {
            loading: true,
            module: {},
            advertisement: null,
            articlesRead: {},
            quizProgressRequire: 0,
            userProgress: 0,
            shownVideosIDs: [],
            timeOutFunction: null,
            buying: false,
            isMoreText: false,
        };
    },
    watch: {
        userProgress(val) {
            this.sendPercentage(val);
        },
        'shownVideosIDs.length'(length) {
            if(this.moduleType == 'video') {
                let percent = parseInt((length / this.module.material?.length) * 100);
                percent = parseInt((this.quizProgressRequire / 100) * percent);

                if(this.userProgress < percent) {
                    this.userProgress = percent;
                }
            }
        }
    },
    computed: {
        moduleID() {
            return this.module?.id || 0;
        },
        materialCode() {
            return this.$route.params.code;
        },
        bioLength() {
            return this.module.speak_info_bio?.length || 0
        },
        moduleType() {
            return this.module.module_type
        },
        progressValue() {
            if(!this.userProgress || !this.quizProgressRequire) return 0;

            return parseInt((this.userProgress / this.quizProgressRequire) * 100);
        },
        isQuizVerified() {
            if(this.module.quiz_verify) return true;

            return this.progressValue >= this.quizProgressRequire;
        }
    },
    methods: {
        displayVideo(videoID) {
            if(!this.shownVideosIDs.includes(videoID)) {
              this.shownVideosIDs.push(videoID);  
            }

            this.$store.commit('quiz/UPDATE_CURRENT_VIDEO_ID', videoID);
            this.$root.$emit("bv::show::modal", "video-viewer-modal");
        },
        buyCurrentModule() {

            this.buying = true
            const data = {
                module_id: this.module.id,
            }

            buyModule(data).then(res => {
                if (res.status == 200) {
                    const response = res.data;
                    let sucessMsg =  response?.message

                    if (response?.Object?.message) {
                        sucessMsg = response.Object.message
                    }

                    this.module.owned = true;
                    
                    this.$notify(sucessMsg);
                }
            }).finally(() => this.buying = false);
        },
        makeMaterialVisited(materialType, materialID) {
            const data = {
                module_type: materialType,
                material_id: `${materialID}`
            }
            materialVisited(data)
        },
        videoID(url) {
            if (url) {
                const urlArry = url.split('/')
                return urlArry[urlArry.length - 1]
            }
                
            return false;
        },
        getMaterialLength(material) {
            if (material?.length) return material.length
            return 0
        },
        getModuleDetails(update) {
            this.loading = true;

            singleModule(this.materialCode).then(res => {
                if (res.status == 200) {
                    const moduleDetails = res.data?.data || null;

                    if(!moduleDetails) return this.$router.push('/404');

                    if (moduleDetails.module_type == 'article' && moduleDetails.material.length) {
                        moduleDetails.material.map(material => {
                            this.makeMaterialVisited('artical', material.id)
                        })
                    }

                    if (!moduleDetails.restricted) {
                        this.$router.push(`/dashboard?is_restricted=${moduleDetails.id}`);
                        return;
                    }

                    // remove
                    // moduleDetails.quiz_verify = false;

                    this.module = moduleDetails;

                    if (!update) this.userProgress = moduleDetails.progress_bar || 0;
                    this.quizProgressRequire = moduleDetails.quiz_progress_restricted_value || 0; // remove 60 || 0

                    this.getAdvertisementModule(moduleDetails.id);
                    this.makeModuleVisited(moduleDetails.id);
                } else {
                    this.$router.push('/404');
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        getAdvertisementModule(moduleID) {
            advertisementModule(moduleID).then(res => {
                if (res.status == 200) {
                    this.advertisement = res.data?.data?.image || null
                }
            })
        },
        makeModuleVisited(moduleID) {
            moduleVisited(moduleID);
        },
        makeModuleFav() {
            const data = {
                module_id: this.module.id
            }
            storeFavorite(data).then(res => {
                if (res.status == 200) {
                    const response = res.data;

                    this.$notify(response.message);
                    this.module.is_favorite = !this.module.is_favorite 
                }
            });
        },
        sendPercentage(percentage) {
            const data = {
                module_id: this.module.id,
                percentage
            }
            sendProgressPercentage(data);
        },
        isElementOnTheView(index) {
            const ID = `art_${index}`
            const ele = document.getElementById(ID)
            const hT = ele.offsetTop;
            const hH = ele.offsetHeight;
            const wH = window.outerHeight;
            const wS = window.scrollY;

            if (wS > ( hT + hH - wH )) {
                this.articlesRead[`art_${index}`] = true
            }
        },
        getReadArticlePercentage() {
            const articlesCount = this.module.material?.length || 0;
            const validTypes = this.module.module_type == 'article' || this.module.module_type == 'ppt';

            if (validTypes && articlesCount) {
                for (let index = 0; index < articlesCount; index++) {
                    this.isElementOnTheView(index);
                }

                let percent = this.calculateArticlesProgress();
                percent = parseInt((this.quizProgressRequire / 100) * percent);

                if(this.userProgress < percent) {
                    this.userProgress = percent;
                }
            }
        },
        calculateArticlesProgress() {
            const allArticlesCount = this.module.material?.length
            const articleViews = this.articlesRead;
            let countArticleRead = 0

            for (const article in articleViews) {
                if (Object.hasOwnProperty.call(articleViews, article)) {
                    countArticleRead = countArticleRead + 1
                }
            }

            const percentage = parseFloat(((countArticleRead / allArticlesCount) * 100).toFixed())

            return percentage;
        },
        takeQuiz() {
            // in case quiz expired
            if(this.module.is_quiz_expired) {
                this.$root.$emit("bv::show::modal", "expired-quiz-modal");
                return;
            }

            console.log(this.module);

            // in case quiz needs a feedback
            if(!this.module.feedBack_user) {
                this.$store.commit('quiz/UPDATE_CURRENT_MODULE', this.module);
                this.$root.$emit("bv::show::modal", "feedback-modal");
                return;
            }

            // in case user is not update his account data
            if(!this.module.is_user_updated) {
                this.$root.$emit("bv::show::modal", "update-data-modal");
                return;
            }

            if (this.isQuizVerified) {
                const URL = `/quiz/${this.module.quiz_code}?quiz_id=${this.module.quiz_id}&partner_id=${this.module.partner_id}&speciality_id=${this.module.speciality_id}&verified=true`;

                this.$router.push(URL);
            }
        },
    },
    created() {
        if (!this.materialCode) {
            this.$router.push('/404');
        }
    },
    mounted() {
        // http://localhost:8080/module/003
        if(!this.$store.state.auth.isLogin) {
            this.$router.push('/?must_login=true');
            return;
        }
        
        this.getModuleDetails();

        document.addEventListener('scroll', () => {
            this.getReadArticlePercentage();
        });
    },
    destroyed() {
        this.$root.$emit("bv::hide::modal", "feedback-modal");
        this.$store.commit('quiz/UPDATE_CURRENT_MODULE', null);
    }
};
</script>

<style lang="scss">
    .player .vp-sidedock {
        display: none !important;
    }

    .owned {
        display: inline-block;
        background-color: #E1D542;
        padding: 0.3rem 1.5rem;
        border-radius: 15px;
        color: #fff;
    }

    .video-card .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 99;
        cursor: pointer;
    }

    .video-card-img {
        .plyr__controls {
            display: none !important;
        }
    }

    .video-count {
        color: #007EC6;
        font-weight: 600;
        margin-right: 10px;
    }
</style>
