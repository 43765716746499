import { API, logError } from "./index";

const getFeedbackQuestions = async (moduleID) => {
    try {
        return await API().get(`/feed-back-show?module_id=${moduleID}`);
    } catch (error) {
        return logError(error);
    }
}

const submitFeedback = (data) => {
    return new Promise( async (resolve, reject) => {
        const res = await API().post('/feed-back', data).catch(e => e.response);

        if(res.status == 200) resolve(res.data);

        reject(res);
    }) 
}

const getCountries = async () => {
    try {
        return await API().get('/countries');
    } catch (error) {
        return logError(error);
    }
}

const getSpecialty = async () => {
    try {
        return await API().get('/specialty');
    } catch (error) {
        return logError(error);
    }
}

const sendUserFormData = async (data) => {
    try {
        return await API().post('/response', data);
    } catch (error) {
        return logError(error);
    }
}


const getQuiz = async (id, page_id) => {
    try {
        return await API().get(`/quiz/${id}?page=${page_id}`);
    } catch (error) {
        return logError(error);
    }
}

const sendQuizAnswers = async (data) => {
    try {
        return await API().post('/response-answer', data);
    } catch (error) {
        return logError(error);
    }
}

export {
    getFeedbackQuestions,
    submitFeedback,
    getCountries,
    getSpecialty,
    sendUserFormData,
    getQuiz,
    sendQuizAnswers
};